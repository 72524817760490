.nameInput {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.placeBottom {
    top: auto !important;
    position: fixed;
    bottom: 0 !important;
}

.newPlayer {
    width: 261px;
    height: 56px;
    margin-top: 16px;
}

.spaceBot {
    margin-bottom: 16px !important;
}

.playButtonDesktop {
    width: 357px !important;
    display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.roboto {
    font-family: 'Roboto', sans-serif !important;
}
.hot-enabled {
    font-family: "Montserrat";
    font-size: x-large;
    font-weight: bold;
    display: inline;
    vertical-align: bottom;
    background: -webkit-linear-gradient(-90deg, #FF5C5C 37.5%, #FFD15C 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hot-disabled {
    font-family: "Montserrat";
    font-size: x-large;
    font-weight: bold;
    display: inline;
    vertical-align: bottom;
    background: -webkit-linear-gradient(-90deg, #616161 0%, #ADADAD 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.illustrateImage {
    max-height: 20vh;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.charHeader {
    padding: auto;
    align-items: center;
    height: 88px;
}

.charStep {
    height: calc(100vh - 88px - 56px - 54px);
    display: flex;
    padding: 16px;
}

.desktopModal {
    width: 70vh;
    padding: 0;
    background: #f8f9fa !important;
    margin: 8vh auto !important;
}

.charColor {
    color: white !important;
}

.fullWidth {
    width: 100%;
}

@media only screen and (min-width: 576px) {
    .charStep {
        height: 50vh;
        display: flex;
        padding: 32px 64px;
    }

    .charColor {
        color: #121212 !important;
    }
}

html, body {
    height: 100vh;
}

#root {
    height: 100%;
    background-color: #4f5b62;
}

.whiteColor {
    color: white !important;
}

.whiteText {
    color: white !important;
    font-size: large !important;
}

.clearText {
    color: #b7b7b7 !important;
}

.secondaryText {
    color: #d9761a !important;
}

.secondaryBack {
    background-color: #d9761a !important;
}

.primaryBack {
    background-color: #383838 !important;
}

.secondaryLightBack {
    background-color: #ffb65c !important;
}

.primaryLightBack {
    background-color: #616161 !important;
}